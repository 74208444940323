import React, { useState } from "react";
import { graphql } from "gatsby";

import Layout from "../containers/layout";

import Hero from "../components/hero";
import HeroImage from "../components/heroImage";
import HeroHalfImage from "../components/heroHalfImage";
import InfoRows from "../components/InfoRows";
import InfoRowsRight from "../components/infoRowsRight";
import CTAColumns from "../components/cta-columns";
import CTA from "../components/cta";
import Content from "../components/content";
import PicColumns from "../components/picColumns";
import QuoteColumns from "../components/quote";
import FeatureVid from "../components/featureVid";
import Compensation from "../components/compensation";
import Locations from "../components/locations";
import Showcase from "../components/showcase";
import Events from "../components/events"

import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Bulletin from "../components/bulletin";
import Joblist from "../components/joblist";
import Gallery from "../components/gallery";
import Codeblock from "../components/codeblock";

//you would define components you want to reuse here

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    route: sanityRoute(id: { eq: $id }) {
      slug {
        current
      }
      useSiteTitle
      page {
        ...PageInfo
      }
      openGraph {
        description
        image {
          ...SanityImage
        }
      }
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      primaryColor {
        hex
      }
      secondaryColor {
        hex
      }
      title
      phoneNumber
      email
      facebook
      twitter
      instagram
      linkedIn
      openGraph {
        title
        description
        image {
          ...SanityImage
        }
      }
    }
  }
`;

const Page = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const page = data.page || data.route.page;

  const content = (page._rawContent || [])
    .filter(c => !c.disabled)
    .map((c, i) => {
      let el = null;
      switch (c._type) {
        case "infoRows":
          el = <InfoRows key={c._key} {...c} />;
          break;
        case "infoRowsRight":
          el = <InfoRowsRight key={c._key} {...c} />;
          break;
        case "hero":
          el = <Hero key={c._key} {...c} />;
          break;
        case "heroImage":
          el = <HeroImage key={c._key} {...c} />;
          break;
        case "heroHalfImage":
          el = <HeroHalfImage key={c._key} {...c} />;
          break;
        case "featureVid":
          el = <FeatureVid key={c._key} {...c} />;
          break;
        case "ctaColumns":
          el = <CTAColumns key={c._key} {...c} />;
          break;
        case "ctaPlug":
          el = <CTA key={c._key} {...c} />;
          break;
        case "content":
          el = <Content key={c._key} {...c} />;
          break;
        case "picColumns":
          el = <PicColumns key={c._key} {...c} />;
          break;
        case "quote":
          el = <QuoteColumns key={c._key} {...c} />;
          break;
        case "compensation":
          el = <Compensation key={c._key} {...c} />;
          break;
        case "locations":
          el = <Locations key={c._key} {...c} />;
          break;
        case "bulletin":
          el = <Bulletin key={c._key} {...c} />;
          break;
        case "gallery":
          el = <Gallery key={c._key} {...c} />;
          break;
        case "events":
          el = <Events key={c._key} {...c} />;
          break;
        case "showcase":
          el = <Showcase key={c._key} {...c} />;
          break;
        case "joblist":
          el = <Joblist key={c._key} {...c} />;
          break;
        case "codeblock":
          el = <Codeblock key={c._key} {...c} />;
          break;
        default:
          el = null;
      }
      return el;
    });

  const gradient = {
    from: (site.primaryColor && site.primaryColor.hex) || "#d53369",
    to: (site.secondaryColor && site.secondaryColor.hex) || "#daae51"
  };

  const menuItems = page.navMenu && (page.navMenu.items || []);
  const pageTitle = data.route && !data.route.useSiteTitle && page.title;
  const navPlace = page.navPlacement
  const pageDesc = data.route && data.route.openGraph.description || site.openGraph.description;
  const pageImage = data.route && data.route.openGraph.image || site.openGraph.image


  return (
    <Layout navMenuItems={menuItems} navPlacement={navPlace} site={site} textWhite={false}>
      <SEO
        title={pageTitle}
        siteTitle={site.title}
        description={pageDesc}
        keywords={site.keywords}
        image={pageImage}
        bodyAttr={{
          class: "leading-normal tracking-normal text-white gradient"
        }}
        gradient={gradient}
      />
      <div>{content}</div>
    </Layout>
  );
};

export default Page;
