import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image";
import PortableText from "./portableText";
import clientConfig from "../../client-config";
import CTALink from "./CTALink";

import { getGatsbyImageData } from "gatsby-source-sanity";

import * as styles from "./showcase.module.css";

//props/variables are fed in from page.js

//background image for a section
const maybeImage = illustration => {
  let img = null;
  if (illustration && illustration.image && illustration.image.asset && !illustration.disabled) {
    const imageData = getGatsbyImageData(
      illustration.image,
      { maxWidth: 960 },
      clientConfig.sanity
    );

    img = (illustration.image.asset.url);
  }
  return img;
};

//small images like icons or logos
const maybeIcon = icon => {
  let iconImg = null;
  if (icon && icon.image && icon.image.asset && !icon.disabled) {
    const iconData = getGatsbyImageData(
      icon.image,
      { maxWidth: 960 },
      clientConfig.sanity
    );

    iconImg = <GatsbyImage
      className="mr-8 w-40 h-40 text-center"
      image={iconData}
      alt={icon.image.alt}
    />
  }
  return iconImg;
};

//rows on the right side
//as seen on the partnership page
const CTARow = ({ heading, tagline, cta, altheading, illustration = [], icon }) => {
  const img = maybeImage(illustration);
  const iconImg = maybeIcon(icon);

  return (
    <div 
      className={`${img ? 'h-screen' : ''} mx-auto`}
      style={{ backgroundImage: `url('${img}?nf_resize=fit&w=1296&h=1296')` }}
    >
      <div className="w-full py-16 justify-center items-start text-left">
      <div className="half-container md:ml-8">
      <div className="w-full">{iconImg}</div>
        {heading && (<h1 className="mb-4 text-4xl md:text-5xl font-bold leading-tight">{heading}</h1>) }
        {altheading && (<h2 className="my-4 text-2xl md:text-3xl font-bold leading-tight">{altheading}</h2>) }
        {tagline ? (
          <div className="leading-normal text-xl">
            <PortableText blocks={tagline} />
          </div>
        ): ''}
        {cta && cta.title && (
          <CTALink
            {...cta}
            buttonActionClass="mx-auto bg-green-500 text-white btnstyle"
            linkActionClass="hover:underline text-green-500 font-bold"
          />
        )}
      </div>
      </div>
      </div>
  );
};

//Main content on the left
const Showcase = ({ heading, tagline, cta, rows, illustration = [] }) => {
  const img = maybeImage(illustration);
  const ros = rows
    .filter(r => !r.disabled)
    .map((r, i) => {
      return <CTARow key={r._key} {...r} />;
    });
  return (
    <div className={`${styles.showcase} grid grid-cols-1 md:grid-cols-2`}>
      {/* Left col */}
      <div>
        <div 
          className={`${styles.headchild} w-full items-end text-left`}
          style={{ 
            backgroundImage: `url('${img}?nf_resize=fit&w=1296&h=1296')`, 
            backgroundSize: 'cover',
            overflow: 'hidden',
        }}
        >
          <div className={`${styles.coverpost} grid items-center`}>
            <div className="half-container px-3 md:px-12">
              <h1 className="my-4 text-4xl md:text-5xl font-bold leading-tight">{heading}</h1>
              <div className="leading-normal text-xl md:text-2xl">
                <PortableText blocks={tagline} />
              </div>
              {cta && cta.title && (
                <CTALink
                  {...cta}
                  buttonActionClass="mx-auto hover:underline border-2 border-green-500 text-white-800 font-bold my-6 py-4 px-8 shadow-lg"
                  linkActionClass="hover:underline text-green-500 font-bold"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Right col */}
      <div className={`${styles.content} md:pl-12 p-3 pt-20`}>
        {ros}
      </div>
    </div>
  );
}

export default Showcase;