import React from 'react';
import PortableText from "./portableText";
import CTALink from "./CTALink";

import * as styles from "./blog-post.module.css";

//props are fed in from page.js

//This is a large text block like found on privacy-statement. Styles come from the intial blog pages. 
function Content(props) {
  return (
    <section className={`${props.mobrules} md:${props.rules}`}>
      <div className="container mx-auto pt-4 pb-12">
        <p className="uppercase tracking-loose w-full">{props.label}</p>
        <h1 className="my-4 text-5xl font-bold leading-tight">{props.heading}</h1>
        <h2 className="my-4 text-3xl font-bold leading-tight">{props.altHeading}</h2>
        <div className="leading-normal text-2xl mb-8">
          <PortableText blocks={props.tagline} />
        </div>
        {props.cta && props.cta.title && (
          <CTALink
            {...props.cta}
            buttonActionClass="mx-auto ml-4 bg-green-500 text-white-800 font-bold my-6 py-4 px-8 shadow-lg"
            linkActionClass="hover:underline text-green-500 font-bold"
          />
        )}
        <div className={styles.mainContent}>
          <PortableText blocks={props.body} />
        </div>
      </div>
    </section>
  );
}

export default Content;