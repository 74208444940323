import React from 'react';
import parse from 'html-react-parser';

//props/variables are fed in from page.js

//rules and mobrules are tailwind classes defined in the cms
//this lists available jobs for Unified
const Codeblock = ({name, mobrules, rules, codesection, backgroundColor}) => {

  return (
    <div>
      <section className={`${mobrules} md:${rules} px-3 md:px-0`}>
        <div className={`container mx-auto p-4 md:p-10 rounded-lg ${backgroundColor} text-black pb-12`}>
          <div className="w-full h-20 font-bold mb-4 text-4xl md:text-5xl">{name}</div>
          {codesection && codesection.map((c, i=0) => {
            return (
              <div key={i + 1} className={`${c.codeclass ? c.codeclass : 'none'}`} data-group-by={`${c.codedata ? c.codedata : 'none'}`} id={`${c.codeid ? c.codeid : i+1+"code"}`} dangerouslySetInnerHTML={{__html: c.codeblock.code}} />
            );
          })}
        </div>
      </section>
    </div>
  );
}

export default Codeblock