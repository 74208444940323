import React from "react";
import PortableText from "./portableText";
import clientConfig from "../../client-config";
import CTALink from "./CTALink";

import { getGatsbyImageData } from "gatsby-source-sanity";
import { GatsbyImage } from "gatsby-plugin-image";

//props/variables are fed in from page.js

//image for column
const maybeImage = illustration => {
  let img = null;
  if (illustration && illustration.image && illustration.image.asset && !illustration.disabled) {
    const imageData = getGatsbyImageData(
      illustration.image,
      { maxWidth: 960 },
      clientConfig.sanity
    );

    img = (
      <GatsbyImage
        className="h-full"
        image={imageData}
        alt={illustration.image.alt}
      />
    );
  }
  return img;
};

//each column, content can be centered from the CMS, colrules are tailwind classes defined in the CMS. 
const CTAColumn = ({ width, colrules, title, body, ctas, center, illustration = [] }) => {
  const className = `${center ? 'text-center' : ''} grid grid-row-7 px-6 pt-6 h-full ${colrules} bg-white rounded-lg text-black`;
  const img = maybeImage(illustration);

  const actions = ctas
    .filter((c,) => c.title)
    .map(c => {
      return (
        <div className={`${center ? 'mx-auto' : ''} flex items-end overflow-visible`}>
          <div className="flex items-end justify-start mx-auto md:mx-0">
            <CTALink
              key={`cta_${c.title}`}
              {...c}
              buttonActionClass={`${c.btnrules}  btnstyle`}
              linkActionClass="hover:underline text-green-500 font-bold"
            />
          </div>
        </div>
      );
    });

  return (
    <div key={title} className={className}>
      { img && (<div className="w-40 h-40 mx-auto mb-6">{img}</div>)}
      <div className="flex-1 row-span-5 overflow-hidden">
        { title && (<div className="w-full font-bold text-xl md:text-2xl mb-4">{title}</div>)}
        <div className="text-lg mb-5">
          <PortableText blocks={body} />
        </div>
      </div>
      {actions}
      </div>
  );
};

// Call to action columns as seen on the home page
// rules and mobrules are tailwind classes defined in the cms
const CTAColumns = ({ title, altHeading, rules, mobrules, colrules, columns, tagline, centerCta }) => {
  const cols = columns
    .filter(c => !c.disabled)
    .map((c, i) => {
      return <CTAColumn colrules={colrules} center={centerCta} key={c._key} {...c} />;
    });

  return (
    <section className={`${mobrules} md:${rules}`}>
      <div className="container px-3 mx-auto flex flex-wrap pb-12">
        { title && (
          <h2 className={`${tagline ? '' : 'mb-8 md:mb-16'} w-full my-4 text-4xl md:text-5xl font-bold leading-tight text-left md:text-center text-white`}>
            {title}
          </h2>
        )}
        { altHeading && (
          <div className="w-full mt-4 mb-10 text-2xl md:text-3xl font-bold leading-tight md:text-center">{altHeading}</div>
        )}
        <div className="leading-normal text-xl md:text-2xl text-center mb-8">
            <PortableText blocks={tagline} />
          </div>
        <div className={`md:grid-cols-${columns.length} mt-10 w-full mx-auto grid grid-cols-1 gap-6`}>
        {cols}
        </div>
      </div>
    </section>
  );
};

export default CTAColumns;
