import React from "react";
import PortableText from "./portableText";
import clientConfig from "../../client-config";
import CTALink from "./CTALink";

import { getGatsbyImageData } from "gatsby-source-sanity";
import { GatsbyImage } from "gatsby-plugin-image";

//props/variables are fed in from page.js

//large image on left or right
const maybeImage = illustration => {
  let img = null;
  if (illustration && illustration.image && illustration.image.asset && !illustration.disabled) {
    const imageData = getGatsbyImageData(
      illustration.image,
      { maxWidth: 960 },
      clientConfig.sanity
    );

    img = (
      <GatsbyImage
        className="h-full"
        image={imageData}
        alt={illustration.image.alt}
      />
    );
  }
  return img;
};

// small image with the content
const maybeIcon = icon => {
  let iconImg = null;
  if (icon && icon.image && icon.image.asset && !icon.disabled) {
    const iconData = getGatsbyImageData(
      icon.image,
      { maxWidth: 960 },
      clientConfig.sanity
    );

    iconImg = <GatsbyImage
      className="w-48 h-auto mb-8 text-center"
      image={iconData}
      alt={icon.image.alt}
    />
  }
  return iconImg;
};

//hero is widely used throughout the site with a large image on one side and content on the other
function Hero(props) {
  const img = maybeImage(props.illustration);
  const iconImg = maybeIcon(props.icon);
  return (
    <section className={`${props.mobrules} md:${props.rules} mx-auto grid grid-cols-1 md:grid-cols-2 items-center`}>
      {/* Left col */}
      <div className={`${props.contrules} w-full md:my-16 justify-center px-3 items-end text-left ${props.position ? 'md:col-start-2' : ''}`}>
        <div className="half-container mt-8 md:mt-0 mx-auto">
          { props.icon && (<div>{iconImg}</div>) }
          { props.bigheading && (<h1 className="my-4 text-4xl md:text-5xl font-bold leading-tight">{props.bigheading}</h1>) }
          { props.heading && (<h2 className="my-4 text-3xl md:text-4xl font-bold leading-tight">{props.heading}</h2>) }
          { props.altHeading && (<div className="my-4 text-2xl md:text-3xl font-bold leading-tight">{props.altHeading}</div>) }
          <div className="leading-normal mb-8 text-lg">
            <PortableText blocks={props.tagline} />
          </div>
          {props.cta && props.cta.title && (
            <CTALink
              {...props.cta}
              buttonActionClass={`${props.cta.btnrules} mx-auto btnstyle`}
              linkActionClass="hover:underline text-green-500 font-bold"
            />
          )}
        </div>
        
      </div>
      {/* Right col */}
      <div className={`${props.imgrules} w-full h-full px-3 md:px-0 text-center row-start-1 ${props.position ? '' : 'md:col-start-2'}`}>{img}</div>
    </section>
  );
}

export default Hero;
