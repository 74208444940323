import React from "react";
import PortableText from "./portableText";
import clientConfig from "../../client-config";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { getGatsbyImageData } from "gatsby-source-sanity";
import { GatsbyImage } from "gatsby-plugin-image";

//props/variables are fed in from page.js

//quote images
const maybeImage = illustration => {
  let img = null;
  if (illustration && illustration.image && illustration.image.asset && !illustration.disabled) {
    const imageData = getGatsbyImageData(
      illustration.image,
      { maxWidth: 960 },
      clientConfig.sanity
    );

    img = (
      <GatsbyImage
        className="h-full"
        image={imageData}
        alt={illustration.image.alt}
      />
    );
  }
  return img;
};

//as seen in the quote section on the home page
const QuoteColumn = ({ width, label, title, body, name, company, illustration = [] }) => {
  const className = `grid grid-cols-1 md:grid-cols-3 gap-2 px-4`;
  const img = maybeImage(illustration);

  return (
      <div className={className}>
        <div className="col-span-2">
          <p className="text-gray-300 text-2xl mb-5">
            <PortableText blocks={body} />
          </p>
        </div>
        <div className="text-right mt-6 md:mt-0">
          <div className="w-full mb-2">{img}</div>
          <p className="w-full text-gray-300 text-sm font-bold">{name}</p>
          <p className="w-full font-bold text-sm text-gray-300">{title}</p>
          <p className="w-full text-gray-300 text-sm font-bold">{company}</p>
        </div>
      </div>
  );
};

const QuoteColumns = ({ title, columns, mobrules, rules, tagline }) => {
  const cols = columns
    .filter(c => !c.disabled)
    .map((c, i) => {
      return <QuoteColumn width={columns.length} key={c._key} {...c} />;
    });
  
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section className={`${mobrules} md:${rules}`}>
      <div className="container mx-auto block md:pt-4 md:pb-12">
        { title && (
          <h2 className={`${tagline ? '' : 'mb-16'} w-full my-4 text-5xl font-bold leading-tight text-center text-white`}>
            {title}
          </h2>
        )}
        { tagline && (
          <div className="leading-normal text-2xl text-center mb-8">
              <PortableText blocks={tagline} />
          </div>
        )}
        <Slider {...settings}>
          {cols}
        </Slider>
      </div>
    </section>
  );
};

export default QuoteColumns;