import React from "react";
import PortableText from "./portableText";
import CTALink from "./CTALink";

import { getGatsbyImageData } from "gatsby-source-sanity";
import clientConfig from "../../client-config";
import { GatsbyImage } from "gatsby-plugin-image";

//props/variables are fed in from page.js

//image
const maybeImage = illustration => {
  let img = null;
  if (
    illustration &&
    illustration.disabled !== true &&
    illustration.image &&
    illustration.image.asset
  ) {
    const imageData = getGatsbyImageData(
      illustration.image,
      { maxWidth: 960 },
      clientConfig.sanity
    );

    img = (
      <GatsbyImage
        className="w-full sm:h-64 mx-auto"
        image={imageData}
        alt={illustration.image.alt}
      />
    );
  }
  return img;
};

//not currently in use. 
// these are small content rows w/ images to display on future pages
const InfoRow = props => {
  const img = maybeImage(props.illustration);
  const sizeClass = img ? "sm:w-1/2" : "sm:w-1/1";
  return (
    <div className={"grid grid-cols-1 md:grid-cols-2"}>
      <div className={"px-3 "}>
        <h3 className="text-3xl font-bold leading-none mb-3">{props.title}</h3>
        <p className="mb-8">
          <PortableText blocks={props.text} />
        </p>
        {props.cta && props.cta.title && (
          <CTALink
            {...props.cta}
            buttonActionClass="mx-auto hover:underline border-2 border-green-500 text-white-800 font-bold mb-6 py-4 px-8 shadow-lg"
            linkActionClass="hover:underline text-green-500 font-bold"
          />
        )}
      </div>
      {img && <div className={"w-full px-3 md:px-0" + sizeClass}>{img}</div>}
    </div>
  );
};

const InfoRowFlipped = props => {
  const img = maybeImage(props.illustration);
  const sizeClass = img ? "sm:w-1/2" : "sm:w-1/1";
  return (
    <div className={"grid grid-cols-1 mt-16 md:mt-32 md:grid-cols-2"}>
      {img && <div className={"w-full px-3 md:px-0" + sizeClass}>{img}</div>}
      <div className={"px-3 "}>
        <h3 className="text-3xl font-bold leading-none mb-3">{props.title}</h3>
        <p className=" mb-8">
          <PortableText blocks={props.text} />
        </p>
        {props.cta && props.cta.title && (
          <CTALink
            {...props.cta}
            buttonActionClass="mx-auto border-2 border-green-500 text-white-800 font-bold my-3 mb-6 px-8 shadow-lg"
            linkActionClass="hover:underline text-green-500 font-bold"
          />
        )}
      </div>
    </div>
  );
};

const InfoRows = props => {
  const contentRows = (props.rows || [])
    .filter(r => !r.disabled)
    .map((r, i) => {
      return i % 2 === 0 ? <InfoRow key={r._key} {...r} /> : <InfoRowFlipped key={r._key} {...r} />;
    });

  return (
    <section className={`${props.mobrules} md:${props.rules}`}>
      <div className="container mx-auto pt-4 pb-12">
        { props.title && (
          <h2 className="w-full my-2 mb-16 text-5xl font-bold leading-tight text-center">
            {props.title}
          </h2>
        )}
        {contentRows}
      </div>
    </section>
  );
};

export default InfoRows;
