import React from "react";
import PortableText from "./portableText";
import clientConfig from "../../client-config";

import { getGatsbyImageData } from "gatsby-source-sanity";
import { GatsbyImage } from "gatsby-plugin-image";

//props/variables are fed in from page.js

//image
const maybeImage = illustration => {
  let img = null;
  if (illustration && illustration.image && illustration.image.asset && !illustration.disabled) {
    const imageData = getGatsbyImageData(
      illustration.image,
      { maxWidth: 960 },
      clientConfig.sanity
    );

    img = (
      <GatsbyImage
        className="h-full"
        image={imageData}
        alt={illustration.image.alt}
      />
    );
  }
  return img;
};

//single column
const CTAColumn = ({ title, content, center, illustration = [] }) => {
  const className = `${center ? 'text-center' : ''} w-full my-10`;
  const img = maybeImage(illustration);

  return (
    <div className={className}>
      <div className="w-full mb-8">{img}</div>
      { title ? (<h3 className="w-full font-bold text-xl pb-2 md:text-2xl">{title}</h3>) : ''}
      { content ? (
        <div className="text-white text-lg md:mb-5">
          <PortableText blocks={content} />
        </div>
      ) : ''}
    </div>
  );
};

//this component is based on the previous events section on uCares
// numcols is number of columns defined in the CMS
// rules and mobrules are taiwind classes drfined in the cms
const Events = ({ title, numcols, mobrules, rules, columns, tagline, centerCta }) => {
  const cols = columns
    .filter(c => !c.disabled)
    .map((c, i) => {
      return <CTAColumn center={centerCta} key={c._key} {...c} />;
    });

  return (
    <section className={`${mobrules} md:${rules}`}>
      <div className="container px-3 mx-auto flex flex-wrap pb-12">
        <h2 className={`${tagline ? '' : 'mb-8 md:mb-16'} w-full my-4 text-4xl md:text-5xl font-bold leading-tight text-left text-white`}>
          {title}
        </h2>
        {tagline && (
          <div className="leading-normal text-xl md:text-2xl mb-8">
            <PortableText blocks={tagline} />
          </div>
        )}
        <div className={`md:grid-cols-${numcols} w-full grid grid-cols-1 gap-12`}>
          {cols}
        </div>
      </div>
    </section>
  );
};

export default Events;