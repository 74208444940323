import React from "react";
import PortableText from "./portableText";
import clientConfig from "../../client-config";

import { getGatsbyImageData } from "gatsby-source-sanity";
import { GatsbyImage } from "gatsby-plugin-image";

//props/variables are fed in from page.js

//based on previous members on uLead. Not currently in use
const maybeImage = illustration => {
  let img = null;
  if (illustration && illustration.image && illustration.image.asset && !illustration.disabled) {
    const imageData = getGatsbyImageData(
      illustration.image,
      { maxWidth: 960 },
      clientConfig.sanity
    );

    img = (
      <GatsbyImage
        className="w-40 h-40 rounded"
        image={imageData}
        alt={illustration.image.alt}
      />
    );
  }
  return img;
};

const CTAColumn = ({ label, heading, body, colrules, illustration = [] }) => {
  const className = `p-6 pt-6 ${colrules}`;
  const img = maybeImage(illustration);

  return (
    <div key={label} className={className}>
      { img && (<div className="mx-auto flex mb-4 items-center ">{img}</div>)}
      { heading && (<div className="w-full mb-4 text-xl md:text-2xl font-bold">{heading}</div>)}
      { body && (
        <div className="text-base md:text-xl">
          <PortableText blocks={body} />
        </div>
      )}
    </div>
  );
};

const Gallery = ({ rules, mobrules, colrules, columns }) => {
  const cols = columns
    .filter(c => !c.disabled)
    .map((c, i) => {
      return <CTAColumn colrules={colrules} key={c._key} {...c} />;
    });

  return (
    <section className={`${mobrules} md:${rules}`}>
      <div className="container px-3 mx-auto flex flex-wrap pb-12">
        <div className={`md:grid-cols-4 w-full grid grid-cols-2 gap-4`}>
          {cols}
        </div>
      </div>
    </section>
  );
};

export default Gallery;