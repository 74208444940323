import React from "react";
import PortableText from "./portableText";
import clientConfig from "../../client-config";

import { getGatsbyImageData } from "gatsby-source-sanity";
import { GatsbyImage } from "gatsby-plugin-image";

import * as styles from "./picColumns.module.css";

//props/variables are fed in from page.js

//each image
const maybeImage = illustration => {
  let img = null;
  if (illustration && illustration.image && illustration.image.asset && !illustration.disabled) {
    const imageData = getGatsbyImageData(
      illustration.image,
      { maxWidth: 960 },
      clientConfig.sanity
    );

    img = (
      <GatsbyImage
        className="h-full"
        image={imageData}
        alt={illustration.image.alt}
      />
    );
  }
  return img;
};

//single column
const CTAColumn = ({ width, illustration = [], title, content }) => {
  const className = `w-1/6 md:w-1/${width} flex-col w-40 flex`;
  const img = maybeImage(illustration);

  return (
    <div 
      className={className} 
    >
      <div className="w-full mb-8">{img}</div>
      { title ? (<div className="w-full font-bold text-lg pb-2 md:text-2xl">{title}</div>) : ''}
      { content ? (
        <div className="text-white text-base md:text-xl md:mb-5">
          <PortableText blocks={content} />
        </div>
      ) : ''}
    </div>
  );
};

//As seen on the brand logos section on the home page. 
//mobrules and rules are taiwlind classes defined in the cms
const PicColumns = ({ title, numcols, mobrules, rules, columns, tagline }) => {
  const cols = columns
    .filter(c => !c.disabled)
    .map((c, i) => {
      return <CTAColumn width={numcols} key={c._key} {...c} />;
    });

  return (
    <section className={`${mobrules} md:${rules}`}>
      <div className="container mx-auto md:pt-4 md:pb-12">
        <h2 className="w-full my-4 text-4xl md:text-5xl font-bold leading-tight text-center text-white">
          {title}
        </h2>
        {tagline ? (
          <div className="leading-normal text-2xl text-center mb-8">
            <PortableText blocks={tagline} />
          </div>  
        ) : ''}
        <div className={`mt-12 flex flex-wrap justify-center`}>
          {cols}
        </div>
      </div>
    </section>
  );
};

export default PicColumns;