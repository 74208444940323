import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image";
import PortableText from "./portableText";
import clientConfig from "../../client-config";
import CTALink from "./CTALink";
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

import { getGatsbyImageData } from "gatsby-source-sanity";

import * as styles from "./hero-image.module.css";

//props/variables are fed in from page.js

//background image
const maybeImage = illustration => {
  let img = null;
  if (illustration && illustration.image && illustration.image.asset && !illustration.disabled) {
    const imageData = getGatsbyImageData(
      illustration.image,
      { maxWidth: 960 },
      clientConfig.sanity
    );

    img = convertToBgImage(imageData);
  }
  return img;
};

//as seen on the homepage
function HeroImage(props) {
  const img = maybeImage(props.illustration);
  return (
    <section 
      className={`${styles.heroFull} ${props.mobmargin} md:${props.margin}`}
    >
      <BackgroundImage
      // Spread bgImage into BackgroundImage:
      {...img}
      id={`herobg`}
      preserveStackingContext
    >
      <div className={`${styles.coverPost} py-32 md:flex md:justify-center md:items-center`}>
        <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center"    >
          <div className="flex flex-col w-full justify-center items-start text-left">
            <p className="uppercase tracking-loose w-full">{props.label}</p>
            <h1 className="my-4 text-4xl md:text-5xl font-bold leading-tight">{props.heading}</h1>
            <div className="leading-normal text-lg md:text-xl">
              <PortableText blocks={props.tagline} />
            </div>
            {props.cta && props.cta.title && (
              <CTALink
                {...props.cta}
                buttonActionClass="bg-green-500 text-white btnstyle mx-auto md:mx-0"
                linkActionClass="hover:underline text-green-500 font-bold"
              />
            )}
          </div>
        </div>
      </div>
      </BackgroundImage>
    </section>
  );
}

export default HeroImage;
