import React from 'react';

//props/variables are fed in from page.js

//rules and mobrules are tailwind classes defined in the cms
//this lists available jobs for Unified
const Joblist = ({name, mobrules, rules}) => {
  
  const jobscore = `
    <script type='text/javascript'>
      (function(d, s, c) {
        if (window._jobscore_loader) { return; } else { window._jobscore_loader = true; }
        var o = d.createElement(s); o.type = 'text/javascript'; o.async = true;
        var sc = d.getElementsByTagName(s)[0]; sc.parentNode.insertBefore(o, sc);
        o.src = ('https:' == d.location.protocol ? 'https:' : 'http:') + '//careers.jobscore.com/jobs/' + c + '/widget.js';
      })(document, 'script', 'unified');
    </script>
  `
  
  return (
    <div>
      <section className={`${mobrules} md:${rules} px-3 md:px-0`}>
        <div className="container mx-auto p-4 md:p-10 rounded-lg bg-white text-black pb-12">
          <div className="w-full h-20 font-bold mb-4 text-4xl md:text-5xl">{name}</div>
          <div class="jobscore-jobs" data-group-by="department">
            <iframe 
              name="js_widget_iframe_1" 
              id="js_widget_iframe_1" 
              title="js_widget_iframe_1" 
              allowtransparency="true" frameborder="0" 
              scrolling="no" 
              width="100%" 
              height="1701px" 
              src="https://widgets.jobscore.com/jobs/unified/widget_iframe?group_by=department&amp;parent_url=https%3A%2F%2Funified.com%2Fcareers&amp;widget_id=js_widget_iframe_1">
            </iframe>
          </div>
        </div>
      </section>
      <div className="hidden">
        {jobscore}
      </div>
    </div>
  );
}

export default Joblist

/* 

<script  type='text/javascript'
  dangerouslySetInnerHTML={{
    __html: `
          (function(d, s, c) {
            if (window._jobscore_loader) { return; } else { window._jobscore_loader = true; }
            var o = d.createElement(s); o.type = 'text/javascript'; o.async = true;
            var sc = d.getElementsByTagName(s)[0]; sc.parentNode.insertBefore(o, sc);
            o.src = ('https:' == d.location.protocol ? 'https:' : 'http:') + '//careers.jobscore.com/jobs/' + c + '/widget.js';
          })(document, 'script', 'unified');
        `,
  }}
/>


<div class="jobscore-jobs" data-group-by="department">
  <iframe 
    name="js_widget_iframe_1" 
    id="js_widget_iframe_1" 
    title="js_widget_iframe_1" 
    allowtransparency="true" frameborder="0" 
    scrolling="no" 
    width="100%" 
    height="1701px" 
    src="https://widgets.jobscore.com/jobs/unified/widget_iframe?group_by=department&amp;parent_url=https%3A%2F%2Funified.com%2Fcareers&amp;widget_id=js_widget_iframe_1">
  </iframe>
</div>

*/