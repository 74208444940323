import React from "react";
import PortableText from "../components/portableText";
import CTALink from "./CTALink";

//props/variables are fed in from page.js

//btnrules are tailwind classes defined in the cms
//no pages are currently using this
const CTA = ({ label, btnrules, title, body, ctas }) => (
  <section className="text-center py-8 md:py-16">
    <div className="container mx-auto pt-4 pb-12">
      { title && (<h2 className="w-full my-2 text-4xl md:text-5xl font-bold leading-tight text-center text-white">{title}</h2>)}

      { body && (
        <p className="my-4 text-2xl md:text-3xl leading-tight">
          <PortableText blocks={body} />
        </p>
      )}

      <div className="flex">
        {(ctas || []).map((c, i) => (
          <div className="flex-1 text-center py-2">
            <CTALink
              key={`cta_${i}`}
              {...c}
              buttonActionClass={`mx-auto ml-4 ${btnrules} btnstyle`}
              linkActionClass="hover:underline text-xl md:text-2xl text-green-500 font-bold"
            />
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default CTA;
