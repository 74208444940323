import React from "react";
import PortableText from "./portableText";
import clientConfig from "../../client-config";
import CTALink from "./CTALink";

import { getGatsbyImageData } from "gatsby-source-sanity";
import { GatsbyImage } from "gatsby-plugin-image";

//props/variables are fed in from page.js

//image
const maybeImage = illustration => {
  let img = null;
  if (illustration && illustration.image && illustration.image.asset && !illustration.disabled) {
    const imageData = getGatsbyImageData(
      illustration.image,
      { maxWidth: 960 },
      clientConfig.sanity
    );

    img = (
      <GatsbyImage
        className="w-3/5"
        image={imageData}
        alt={illustration.image.alt}
      />
    );
  }
  return img;
};

//single column
const CTAColumn = ({ title, content, illustration = [] }) => {
  const className = `w-full my-10 text-center`;
  const img = maybeImage(illustration);

  return (
    <div className={className}>
      <div className="w-full mb-8">{img}</div>
      { title ? (<div className="w-full font-bold text-lg pb-2 md:text-2xl">{title}</div>) : ''}
      { content ? (
        <div className="text-white text-base md:text-xl md:mb-5">
          <PortableText blocks={content} />
        </div>
      ) : ''}
    </div>
  );
};

//based on the locations sections on the About page
//mobrules and rulees are taiwind classes defined in the CMS
const Locations = ({ title, columns, mobrules, rules, tagline, cta }) => {
  const cols = columns
    .filter(c => !c.disabled)
    .map((c, i) => {
      return <CTAColumn width={columns.length} key={c._key} {...c} />;
    });

  return (
    <section className={`${mobrules} md:${rules}`}>
      <div className="container mx-auto px-3">
        <h2 className="w-full my-4 mb-8 text-2xl md:text-5xl font-bold leading-tight text-center">
          {title}
        </h2>
        {tagline ? (
          <div className="leading-normal text-xl md:text-2xl text-center mb-16">
            <PortableText blocks={tagline} />
          </div>  
        ) : ''}
        <div className={`grid grid-cols-${columns.length} gap-x-6`}>
          {cols}
        </div>
        { cta ? (
          <div>
            <hr className="border-white m-8" />
            <div className="flex items-center">
              <CTALink
                {...cta}
                buttonActionClass={`mx-auto bg-green-500 text-white btnstyle`}
                linkActionClass="hover:underline text-green-500 font-bold"
              />
            </div>
          </div>
        ) : ''}
      </div>
    </section>
  );
};

export default Locations;