import React from "react";
import PortableText from "./portableText";
import clientConfig from "../../client-config";

import { getGatsbyImageData } from "gatsby-source-sanity";
import { GatsbyImage } from "gatsby-plugin-image";

//props/variables are fed in from page.js

//image
const maybeImage = illustration => {
  let img = null;
  if (illustration && illustration.image && illustration.image.asset && !illustration.disabled) {
    const imageData = getGatsbyImageData(
      illustration.image,
      { maxWidth: 960 },
      clientConfig.sanity
    );

    img = (
      <GatsbyImage
        className="w-full"
        image={imageData}
        alt={illustration.image.alt}
      />
    );
  }
  return img;
};

//single column. Most variables are optional
const CTAColumn = ({ label, position, heroTitle, altheading, heading, note, disclaimer, body, illustration = [] }) => {
  const className = `p-6 pt-6`;
  const img = maybeImage(illustration);
  //${position ? '' : 'md:col-start-2'}
  return (
    <div key={label} className={className}>
      { heroTitle && (
        <div className={`w-full text-5xl md:text-6xl font-bold leading-tight mb-4 text-green-500`}>
          {heroTitle}
        </div>
      )}
      { heading && (<div className="w-full h-20 font-bold text-4xl md:text-5xl">{heading}</div>)}
      { altheading && (<div className="w-full mb-4 text-xl md:text-2xl">{altheading}</div>)}
      { disclaimer && (<div className="w-full text-gray-400 text-xs md:text-sm">{disclaimer}</div>)}
      { img && (
        <div className="md:grid-cols-2 w-full grid grid-cols-1 gap-6">
           <div className={`${position ? 'md:col-start-2 mr-auto md:flex-row' : 'mr-auto md:mr-0 md:ml-auto md:flex-row-reverse'} h-full row-start-1 flex items-center`}><img className={`${position ? 'mr-4' : 'mr-4 md:mr-0 md:ml-4'}`} src="/img/gold-medal.png" />{img}</div>
          <div className={`${position ? 'md:text-right' : 'md:col-start-2'}`}>
            <div className="w-full text-xs md:text-sm">{note}</div>
            <div className="text-base md:text-xl mb-5">
              <PortableText blocks={body} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

//this component is based on the awards block on the Careers & Culture page. Also on uLead & uCares
//mobrules & rules are tailwind classes defined in the CMS
const Bulletin = ({ columns, rules, mobrules }) => {
  const cols = columns
    .filter(c => !c.disabled)
    .map((c, i) => {
      return <CTAColumn key={c._key} {...c} />;
    });

  return (
    <section className={`${mobrules} md:${rules}`}>
      <div className="container px-3 mx-auto flex flex-wrap">
        <div className={`md:grid-cols-${columns.length} w-full grid grid-cols-1 gap-6`}>
          {cols}
        </div>
      </div>
    </section>
  );
};

export default Bulletin;