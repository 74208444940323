import React from "react";
import PortableText from "./portableText";
import clientConfig from "../../client-config";
import CTALink from "./CTALink";

import { getGatsbyImageData } from "gatsby-source-sanity";
import { GatsbyImage } from "gatsby-plugin-image";

import * as styles from "./compensation.module.css";

//props are fed in from page.js

//Images for components
const maybeImage = illustration => {
  let img = null;
  if (illustration && illustration.image && illustration.image.asset && !illustration.disabled) {
    const imageData = getGatsbyImageData(
      illustration.image,
      { maxWidth: 960 },
      clientConfig.sanity
    );

    img = (
      <GatsbyImage
        className="w-4/5"
        image={imageData}
        alt={illustration.image.alt}
      />
    );
  }
  return img;
};

//A single column, content can be centered in the CMS
const CTAColumn = ({ title, center, content, illustration = [] }) => {
  const className = `${center ? 'md:text-center' : 'md:text-left'} text-center w-full my-10`;
  const img = maybeImage(illustration);

  return (
    <div className={className}>
      <div className="w-3/4 mx-auto md:w-full mb-8">{img}</div>
      { title ? (<div className="w-full font-bold text-xl pb-2 md:text-2xl">{title}</div>) : ''}
      { content ? (
        <div className="text-white text-lg md:mb-5">
          <PortableText blocks={content} />
        </div>
      ) : ''}
    </div>
  );
};


//mobrules & rules are tailwind classes defined in the cms
//component based on the the compensation block in Careers & Culture
const Compensation = ({ title, mobnumcols, numcols, btnrules, columns, rules, mobrules, tagline, centerCta, cta }) => {
  const cols = columns
    .filter(c => !c.disabled)
    .map((c, i) => {
      return <CTAColumn width={columns.length} center={centerCta}  key={c._key} {...c} />;
    });

  return (
    <section className={`${mobrules} md:${rules}`}>
      <div className="container mx-auto px-3">
        <h2 className="w-full my-4 mb-8 text-4xl md:text-5xl font-bold leading-tight md:text-center">
          {title}
        </h2>
        {tagline ? (
          <div className="leading-normal text-lg md:text-xl md:text-center mb-16">
            <PortableText blocks={tagline} />
          </div>  
        ) : ''}
        <div className={`${styles.gridContainer} md:grid-cols-${numcols} grid-cols-${mobnumcols} gap-x-20`}>
          {cols}
        </div>
        { cta ? (
          <div>
            <hr className="border-white m-8" />
            <div className="flex items-center">
              <CTALink
                {...cta}
                buttonActionClass={`${btnrules} mx-auto btnstyle`}
                linkActionClass="hover:underline text-green-500 font-bold"
              />
            </div>
          </div>
        ) : ''}
      </div>
    </section>
  );
};

export default Compensation;