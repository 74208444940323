import React from 'react';
import PortableText from "./portableText";
import ReactPlayer from "react-player";

import * as styles from "./featureVid.module.css";

//props/variables are fed in from page.js

//based on the video section in uLead
function FeatureVid(props) {
  return (
    <section className={`${props.mobrules} md:${props.rules}`}>
      <div className="container text-center mx-auto pt-4 pb-12">
        <p className="uppercase tracking-loose w-full">{props.label}</p>
        <h1 className="my-4 text-5xl font-bold leading-tight">{props.heading}</h1>
        {props.altHeading && (<h2 className="my-4 text-3xl font-bold leading-tight">{props.altHeading}</h2>)}
        {props.tagline && (
          <div className="leading-normal text-2xl">
            <PortableText blocks={props.tagline} />
          </div>
        )}
        <div className={styles.playerWrapper}>
          <ReactPlayer className={`${styles.reactPlayer} mt-6 mx-auto px-8 mb-6`} width="100%" height="100%" url={props.videoUrl} controls />
        </div>
      </div>
    </section>
  );
}

export default FeatureVid;